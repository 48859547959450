import React from "react";

function Col({ children, ...props }) {
  return (
    <>
      <th {...props}>{children}</th>
    </>
  );
}

Col.displayName = "Table.Col";

export default Col;
