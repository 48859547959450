import React from "react";

function Body({ children }) {
  const oChildrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child);
    }
    return child;
  });

  return (
    <>
      <tbody>{oChildrenWithProps}</tbody>
    </>
  );
}

Body.displayName = "Table.Body";

export default Body;
