import { BUTTON_CLASS, BUTTON_ICON_POSITION, BUTTON_SIZE } from "config/constants/Buttons";
import React from "react";

import Text from "./Text";

const Button = ({
  onClick = null,
  label: sLabel = null,
  full: bIsFull = false,
  square: bSquare = false,
  size: nSize = BUTTON_SIZE.MD,
  className: sCustomClassName = "",
  type: sType = BUTTON_CLASS.PRIMARY,
  containerClassName: sContainerClassName = "",
  icon: oIcon = null,
  iconPosition: sIconPosition = BUTTON_ICON_POSITION.RIGHT,
}) => {

  let sLabelColor = null;
  const aClassName = [
    "cursor-pointer",
    "inline-block",
    "rounded-md",
    "font-medium",
    "border",
    "border-solid",
    "text-center",
    "transition-all duration-150",
    "duration-200",
  ];

  if (oIcon) aClassName.push("flex", "items-center");

  switch (sType) {
    case BUTTON_CLASS.SUCCESS:
      aClassName.push(
        "bg-green-600",
        "border-green-600",
        "hover:bg-green-800",
        "hover:border-green-800",
      );
      sLabelColor = "text-white";
      break;
    case BUTTON_CLASS.DANGER:
      aClassName.push(
        "bg-red-600",
        "border-red-600",
        "hover:bg-red-800",
        "hover:border-red-800",
      );
      sLabelColor = "text-white";
      break;
    case BUTTON_CLASS.CANCEL_OUTLINE:
      aClassName.push(
        "bg-transparent",
        "border-transparent border-b-gray-600",
        "hover:border-gray-500",
        "rounded-none hover:rounded-md",
        "text-gray-500 hover:text-gray-800 dark:hover:text-gray-200",
      );
      sLabelColor = "inherit";
      break;
    case BUTTON_CLASS.WARNING:
      aClassName.push(
        "bg-primary-500 dark:bg-primary-800",
        "border-primary-500 dark:border-primary-800",
        "hover:bg-primary-600",
        "hover:border-primary-600",
      );
      sLabelColor = "text-white";
      break;
    case BUTTON_CLASS.PRIMARY:
    default:
      aClassName.push(
        "bg-primary-500 dark:bg-primary-800",
        "border-primary-500 dark:border-primary-800",
        "hover:bg-primary-600",
        "hover:border-primary-600",
      );
      sLabelColor = "text-white";
      break;
  }

  const aLabelClassName = ["m-auto"];

  switch (nSize) {
    case BUTTON_SIZE.XS:
      aLabelClassName.push("text-xs");
      aClassName.push("py-1", "px-2");
      break;
    case BUTTON_SIZE.SM:
      aLabelClassName.push("text-sm");
      aClassName.push("py-2", "px-4");
      break;
    case BUTTON_SIZE.MD:
    default:
      aLabelClassName.push("text-base");
      aClassName.push("py-2", "px-6");
      break;
    case BUTTON_SIZE.LG:
      aLabelClassName.push("text-lg");
      aClassName.push("py-3", "px-7");
      break;
    case BUTTON_SIZE.XL:
      aLabelClassName.push("text-xl");
      aClassName.push("py-6", "px-12");
      break;
    case BUTTON_SIZE.RESPONSIVE:
      aLabelClassName.push("text-sm md:text-base");
      bSquare
        ? aClassName.push("py-2 px-2 md:py-2 md:px-1")
        : aClassName.push("py-2 px-4 md:py-2 md:px-6");
      break;
  }

  if (bIsFull)
    aClassName.push("w-full");

  aClassName.push(sCustomClassName);

  return <div className={sContainerClassName}>
    <button className={`${aClassName.join(" ")}`} onClick={onClick}>
      {
        oIcon && sIconPosition === BUTTON_ICON_POSITION.LEFT &&
        oIcon
      }
      {
        sLabel
          ? <Text color={sLabelColor} className={aLabelClassName.join(" ")}>{sLabel}</Text>
          : null
      }
      {
        oIcon && sIconPosition === BUTTON_ICON_POSITION.RIGHT &&
        oIcon
      }
    </button>
  </div>;
}

export default Button;
