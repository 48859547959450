import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { SharedContext } from "context/SharedContext";

const Login = React.lazy(() => import(/*webpackChunkName: "Login" */ "pages/auth/LoginPage"));

const CompaniesList = React.lazy(() => import(/*webpackChunkName: "CompaniesList" */ "pages/companies"));
const StylesList = React.lazy(() => import(/*webpackChunkName: "CompaniesList" */ "pages/styles"));

const aPublicRoutes = [
  { key: "login", path: "/", element: <Login /> },
  { key: "notFound", path: "*", element: <Login /> },
];

const aPrivateRoutes = [
  { key: "companies", path: "/", element: <CompaniesList /> },
  { key: "styles", path: "/styles", element: <StylesList /> },
  { key: "notFound", path: "*", element: <CompaniesList /> },
];

export function RouteConfig() {

  const { bIsAuthenticated } = useContext(SharedContext);

  return <Routes>
    {
      bIsAuthenticated ?
        aPrivateRoutes.map(oRoute => <Route {...oRoute} />)
        :
        aPublicRoutes.map(oRoute => <Route {...oRoute} />)
    }
  </Routes>;
}
