import { useState } from "react";

// Hook
const useLocalStorage = (sKey, sInitialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return sInitialValue;
    }
    try {
      // Get from local storage by sKey
      const item = window.localStorage.getItem(sKey);
      // Parse stored json or if none return sInitialValue
      return item ? JSON.parse(item) : sInitialValue;
    } catch (oError) {
      // If error also return sInitialValue
      console.error(oError);
      return sInitialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(sKey, JSON.stringify(valueToStore));
      }
    } catch (oError) {
      // A more advanced implementation would handle the error case
      console.error(oError);
    }
  };
  return [storedValue, setValue];
};

export default useLocalStorage;

export const getLocalStorageItem = (sKey) => JSON.parse(localStorage.getItem(sKey));
