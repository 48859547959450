import React from "react";

function Footer({ children }) {
  return (
    <>
      <tfoot className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
        {children}
      </tfoot>
    </>
  );
}

Footer.displayName = "Table.Footer";

export default Footer;
