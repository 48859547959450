import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CONSTANTS_MESSAGE } from "config/constants/Message";
import {
  actionDismissLoading,
  actionManageMessage,
  actionMessage,
  actionShowLoading
} from "redux/shared/SharedSlice";

import { apiCallLogin, apiCallLogout, apiCallRefreshCredentials } from "./api/AuthService";

const oInitialState = {
  oUser: null,
  bLogout: false,
};

export const actionLogin = createAsyncThunk("auth/actionLogin", async (oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallLogin(oData.email, oData.password);
    oThunk.dispatch(
      actionMessage({
        message: "Bienvenido",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return oSuccess?.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionRefreshCredentials = createAsyncThunk("auth/refreshCredentials", async (sToken, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallRefreshCredentials(sToken);
    return oSuccess.data || null;
  } catch (oError) {
    oThunk.dispatch(actionLogout());
    return null;
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionLogout = createAsyncThunk("auth/actionLogout", async (oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    await apiCallLogout();
  } finally {
    oThunk.dispatch(actionDismissLoading());
    return true;
  }
});

const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: oInitialState,
  reducers: {
    actionAuthClean: oState => {
      oState.bLogout = false;
    },
  },
  extraReducers: oBuilder => {
    oBuilder.addCase(actionLogin.fulfilled, (oState, oAction) => {
      oState.oUser = oAction.payload;
    });
    oBuilder.addCase(actionRefreshCredentials.fulfilled, (oState, oAction) => {
      oState.oUser = oAction.payload;
    });
    oBuilder.addCase(actionLogout.fulfilled, (oState, oAction) => {
      oState.bLogout = true;
      oState.oUser = null;
    });
  },
});

export const selectAuthState = ({ AuthSlice: oState }) => oState;

export const { actionAuthClean } = AuthSlice.actions;

export default AuthSlice.reducer;
