import React from "react";
import PropsTypes from "prop-types";

import Text from "components/Text";

function Modal({
  title: sTitle,
  children: oChildren,
  show: bShow = false,
  className: sClassName = "",
  onClose = () => {},
}) {
  return (
    bShow && <>
      <div className="fixed top-0 right-0 h-screen w-screen z-40 bg-gray-800 opacity-50" />
      <div className="fixed z-50 top-0 right-0 h-screen w-screen shadow rounded flex items-center justify-center" onClick={onClose} >
        <div className={"shadow rounded overflow-hidden max-w-xs md:max-w-md " + sClassName} onClick={oEvent => oEvent.stopPropagation()} >
          <div className="bg-gray-800 dark:bg-gray-900 text-white text-center p-2">
            <Text>{sTitle}</Text>
          </div>
          <div className="bg-white text-dark dark:bg-dark dark:text-gray-300 p-5">
            {oChildren}
          </div>
        </div>
      </div>
    </>
  );
}

Modal.propTypes = {
  title: PropsTypes.string,
  show: PropsTypes.bool.isRequired,
  onClose: PropsTypes.func.isRequired,
};

export default Modal;
