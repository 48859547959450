import React, { Suspense, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { Loading, Menu, Toast } from "components";

import { RouteConfig } from "routes/Router";
import { SharedContext } from "context/SharedContext";
import { selectSharedState } from "redux/shared/SharedSlice";

export default function Layout() {
  const { bDarkTheme, bIsAuthenticated } = useContext(SharedContext);
  const { bLoading } = useSelector(selectSharedState);

  const [bIsOpen, setOpen] = useState(false);

  return (
    <main className={bDarkTheme ? "dark" : ""}>
      <Router>
        <Suspense fallback={<Loading open />}>
          <div className="flex bg-gray-50 dark:bg-dark">
            {bIsAuthenticated && <Menu open={bIsOpen} onChange={setOpen} />}
            <div
              className={
                "w-screen transition-all duration-300 pl-14 " +
                (bIsAuthenticated ? (bIsOpen ? "md:pl-64" : "pl-14") : "pl-0")
              }
            >
              <RouteConfig />
            </div>
          </div>
        </Suspense>
        <Loading open={bLoading} />
        <Toast />
      </Router>
    </main>
  );
}
