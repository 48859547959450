import React from "react";

function Row({ children, isHeaderOrFooter = false, ...props }) {
  const { className, ...rest } = props;
  const sClassName = isHeaderOrFooter
    ? null
    : `bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${className}`;

  return (
    <>
      <tr {...rest} className={sClassName}>
        {children}
      </tr>
    </>
  );
}

Row.displayName = "Table.Row";

export default Row;
