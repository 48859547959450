import oMeta from "meta.json";

export const CONSTANTS_SYSTEM = {
  APP_VERSION: "v0.1",
  APP_DATE: oMeta?.date,
  API_URL: process.env.REACT_APP_API_URL,
  APP_THEME_KEY: "@BrewControlSuperAdmin:DarkTheme",
  APP_TOKEN_KEY: "@BrewControlSuperAdmin:Token",
  APP_SESSION_STORAGE_KEY: "@BrewControlSuperAdmin:Session",
};
