import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  actionDismissLoading,
  actionManageMessage,
  actionShowLoading
} from "redux/shared/SharedSlice";
import { apiCallGetAllCompanies } from "../api/CompaniesServices";

const oInitialState = {
  aCompanies: null,

  bStatus: false,
};

export const actionGetAllCompanies = createAsyncThunk("companies/actionGetAll", async (args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllCompanies();
    return oSuccess?.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

const CompaniesSlice = createSlice({
  name: "CompaniesSlice",
  initialState: oInitialState,
  reducers: {
  },
  extraReducers: oBuilder => {
    oBuilder.addCase(actionGetAllCompanies.fulfilled, (oState, oAction) => {
      oState.aCompanies = oAction.payload;
    });
  },
});

export const selectCompaniesState = ({ CompaniesSlice: oState }) => oState;

// export const { } = CompaniesSlice.actions;

export default CompaniesSlice.reducer;
