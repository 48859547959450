import oAxiosInstance from "config/Axios";

export const apiCallGetAllStyles = () => {
  return oAxiosInstance.get("styles");
};

export const apiCallStylesSave = (data) => {
  return oAxiosInstance.post("styles", { data });
};

export const apiCallStylesUpdate = (id, data) => {
  return oAxiosInstance.put(`styles/${id}`, { data });
};

export const apiCallStylesDelete = (id) => {
  return oAxiosInstance.delete(`styles/${id}`);
};
