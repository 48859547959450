import React from "react";

const ScreenContainer = ({ children: oContent, className: sClassName = null, padding: sPadding = "" }) => {
  const aClassNames = [];

  if (sPadding) aClassNames.push(sPadding);
  else aClassNames.push("px-5 pt-5 md:px-10 md:pt-10");

  if (sClassName) aClassNames.push(sClassName);

  return (
    <div className="bg-gray-100 dark:bg-dark min-h-screen">
      <section className={aClassNames.join(" ")}>{oContent}</section>
    </div>
  );
};

export default ScreenContainer;
