import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiChevronsLeft, FiHome, FiList, FiLogOut, FiMenu } from "react-icons/fi";

import { Image, Text } from "components";
import MenuItem from "./MenuItem";

import { actionLogout, selectAuthState } from "redux/auth/AuthSlice";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import { CONSTANTS_MEASURES } from "config/constants/Measures";

import Logo from "assets/images/png/logo.png";

const Menu = ({ open: bIsOpen, onChange }) => {

  const oDispatch = useDispatch();
  const { oUser } = useSelector(selectAuthState);

  const onLogout = () => {
    oDispatch(actionLogout());
  };

  return <div className={"fixed h-screen transition-all duration-300 shadow-lg z-40 " + (bIsOpen ? "w-64" : "w-14")}>
    <div className="h-full overflow-y-auto bg-gray-50 dark:bg-gray-800">
      <div className={`${bIsOpen ? "grid grid-cols-6" : ""} items-center mb-8 text-center bg-primary-500 dark:bg-primary-800 px-4 py-4`}>
        <span onClick={() => onChange(!bIsOpen)} className={`${bIsOpen ? "flex" : "hidden"} items-center pl-2.5 col-span-5 transition-all cursor-pointer`}>
          <div className="h-10 w-10 mr-3 bg-white rounded-lg" >
            <Image path={Logo} />
          </div>
          <span className="self-center text-lg font-semibold whitespace-nowrap text-white">Brew Control</span>
        </span>
        {
          bIsOpen ?
            <div className="h-10 w-10 text-center cursor-pointer">
              <FiChevronsLeft size={CONSTANTS_MEASURES.FONT_SIZE_EXTRA_LARGE} className="text-white ml-auto h-full" onClick={() => onChange(!bIsOpen)} />
            </div>
            :
            <div className="h-10 text-center cursor-pointer">
              <FiMenu size={CONSTANTS_MEASURES.FONT_SIZE_EXTRA_LARGE} className="m-auto h-full text-white" onClick={() => onChange(!bIsOpen)} />
            </div>
        }
      </div>
      <ul className={"space-y-2 relative " + (bIsOpen ? "px-4" : "px-1")}>
        <MenuItem label="Cervecerías" path="/" onlyIcon={!bIsOpen}>
          <FiHome size={CONSTANTS_MEASURES.FONT_SIZE_LARGE} color={CONSTANTS_COLORS.COLORS.INHERIT} />
        </MenuItem>
        <MenuItem label="Estilos" path="/styles" onlyIcon={!bIsOpen}>
          <FiList size={CONSTANTS_MEASURES.FONT_SIZE_LARGE} color={CONSTANTS_COLORS.COLORS.INHERIT} />
        </MenuItem>
        <MenuItem label="Cerrar sesión" className="mt-8" onlyIcon={!bIsOpen} onClick={onLogout}>
          <FiLogOut size={CONSTANTS_MEASURES.FONT_SIZE_LARGE} color={CONSTANTS_COLORS.COLORS.INHERIT} />
        </MenuItem>
      </ul>
      <div className={"absolute inset-x-0 bottom-0 w-full " + (bIsOpen ? "border-t-2 px-4 py-4" : "px-1 py-4")}>
        <div className="grid grid-cols-12">
          <div className={(bIsOpen ? "col-span-3" : "col-span-12")}>
            <div className="h-12 w-12">
              <Image path={Logo} className="h-12 rounded-full ml-auto object-cover overflow-hidden"></Image>
            </div>
          </div>
          <div className={"col-span-7 align-middle " + (!bIsOpen && "hidden")}>
            <Text className="whitespace-pre">
              {oUser?.name}
            </Text>
            <Text color={CONSTANTS_COLORS.CLASS.GRAY_LIGHT} size={CONSTANTS_MEASURES.CLASS.FONT_SIZE_EXTRA_SMALL} className="whitespace-pre">
              {oUser?.email}
            </Text>
          </div>
        </div>
      </div>
    </div>
  </div>;
}

export default Menu;
