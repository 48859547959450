export const CONSTANTS_THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const CONSTANTS_THEMES_ITEMS = [
  { label: "LIGHT", value: CONSTANTS_THEMES.LIGHT },
  { label: "DARK", value: CONSTANTS_THEMES.DARK },
];

export const CONSTANTS_TEXT_STYLE = {
  DEFAULT: 0,
  LIGHT: 1,
  DARK: 2,
};
