import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

import {
  actionDismissLoading,
  actionManageMessage,
  actionMessage,
  actionShowLoading
} from "redux/shared/SharedSlice";
import { apiCallGetAllStyles, apiCallStylesDelete, apiCallStylesSave, apiCallStylesUpdate } from "../api/StylesServices";

const oInitialState = {
  aStyles: [],
  bSuccess: false,
};

export const actionGetAllStyles = createAsyncThunk("styles/actionGetAll", async (args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllStyles();
    return oSuccess?.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionStylesSave = createAsyncThunk("styles/actionSave", async (data, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallStylesSave(data);
    oThunk.dispatch(actionMessage({ message: oSuccess.message, type: CONSTANTS_MESSAGE.TYPE_SUCCESS }));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    return false;
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionStylesUpdate = createAsyncThunk("styles/actionUpdate", async (data, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallStylesUpdate(data.id, data);
    oThunk.dispatch(actionMessage({ message: oSuccess.message, type: CONSTANTS_MESSAGE.TYPE_SUCCESS }));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    return false;
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionStylesDelete = createAsyncThunk("styles/actionDelete", async (id, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallStylesDelete(id);
    oThunk.dispatch(actionMessage({ message: oSuccess.message, type: CONSTANTS_MESSAGE.TYPE_SUCCESS }));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    return false;
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

const StylesSlice = createSlice({
  name: "StylesSlice",
  initialState: oInitialState,
  reducers: {
    actionStylesClean: oState => {
      oState.bSuccess = false;
    },
  },
  extraReducers: oBuilder => {
    oBuilder.addCase(actionGetAllStyles.fulfilled, (oState, oAction) => {
      oState.aStyles = oAction.payload;
    });
    oBuilder.addCase(actionStylesSave.fulfilled, (oState, oAction) => {
      oState.bSuccess = oAction.payload;
    });
    oBuilder.addCase(actionStylesDelete.fulfilled, (oState, oAction) => {
      oState.bSuccess = oAction.payload;
    });
    oBuilder.addCase(actionStylesUpdate.fulfilled, (oState, oAction) => {
      oState.bSuccess = oAction.payload;
    });
  },
});

export const selectStylesState = ({ StylesSlice: oState }) => oState;

export const { actionStylesClean } = StylesSlice.actions;

export default StylesSlice.reducer;
