import oDayJs from "dayjs";

import { DATES_FORMATS } from "config/constants/Dates";

/**
 * Función que le da un formato a todas las fechas del sistema, centraliza el uso de moment y el formato de las fechas
 * Casteo de fechas del tipo Date
 *
 * @param {string} sDate Fecha en formato Date o string
 * @param {string} sFormat Formato de salida de la fecha recibida
 *
 * @author Leandro Curbelo
 */
export const CastDate = (sDate = new Date(), sFormat = DATES_FORMATS.DEFAULT) => {
  return oDayJs(sDate).format(sFormat);
};

export const GetDayJsObject = (sDate = new Date()) => {
  return oDayJs(sDate);
};

export const CastFloat = nFloat => {
  return nFloat;
};
/**
 * Función que otorga la diferencia entre ambas fechas en la unidad recibida por parámetro
 *
 * @param {string|Date} sSecondDate Fecha que se cree que es posterior a la segunda fecha pasada por parámetro
 * @param {string|Date} sFirstDate Fecha que se cree sucede anterior a la primera fecha pasada por parámetro
 * @param {string} sUnits Unidad en la que se retorna esta diferencia
 *
 * @returns {number}
 */
export const GetDateDifference = (
  sSecondDate = new Date(),
  sFirstDate = new Date(),
  sUnits = DATES_FORMATS.CONSTANTS.UNITS.DAYS,
) => {
  const oFirstDate = oDayJs(sFirstDate);
  const oSecondDate = oDayJs(sSecondDate);
  return oSecondDate.diff(oFirstDate, sUnits);
};
/**
 * Expresión regular para comprobar que la contraseña contenga todo lo especificado y ademas tenga
 *  su largo minímo, a continuación se explica su composición
 *
 * ? [A-Za-z0-9@$!%*?&#] - Especifica los elementos que pueden contener la cadena (A-Z significa todas las mayúsculas, a-z todas las minusculas, 0-9 números y simbolos de forma individual)
 * ? {8,} - Largo de la cadena, su uso es siempre {1, 15} que significa de 1 a 15 caracteres, en nuestro caso significa al menos 8
 * ? (?=.*[a-z]) - Especifica que debe contener al menos una minuscula
 * ? (?=.*[A-Z]) - Especifica que debe contener al menos una mayuscula
 * ? (?=.*[0-9]) - Especifica que debe contener al menos un número
 * ? (?=.*[@$!%*?&#]) - Especifica que debe contener al menos un simbolo de los que se especifican dentro de los parentesís rectos
 * ? /^ - Apertura de la expresión regular
 * ? $/ - Cierre de la expresión regular
 */
export const PasswordValidator = (sPassword = "") => {
  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#])[A-Za-z0-9@$!%*?&#]{8,}$/;
  return PASSWORD_REGEX.test(sPassword.toLowerCase());
};

export const EmailValidator = (sEmail = "") => {
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEX.test(sEmail.toLowerCase());
};

export const PhoneValidator = (sPhone = "") => {
  const PHONE_REGEX = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
  return PHONE_REGEX.test(sPhone.toLowerCase());
};
