import axios from "axios";
import promise from "promise";

import { CONSTANTS_SYSTEM } from "config/constants/System";
import { getLocalStorageItem } from "hooks/useLocalStorage";

const { API_URL, APP_TOKEN_KEY } = CONSTANTS_SYSTEM;

const oAxiosInstance = axios.create({
  baseURL: API_URL,
});

oAxiosInstance.interceptors.request.use(
  async function(oConfig) {
    let sToken = getLocalStorageItem(APP_TOKEN_KEY);
    if (sToken && oConfig.method !== "OPTIONS") {
      oConfig.headers.Authorization = sToken;
    }
    return oConfig;
  },
  function(oError) {
    return promise.reject(oError);
  },
);

oAxiosInstance.interceptors.response.use(
  oResponse => {
    return oResponse.data?.value || oResponse.data;
  },
  oError => {
    // if (oError?.response?.status === 401)
    // store.dispatch(actionLogout());
    if (oError?.response?.data?.message) return promise.reject(oError?.response?.data?.message);
    return promise.reject(oError.message);
  },
);

export default oAxiosInstance;
