import { configureStore } from "@reduxjs/toolkit";

import AuthSlice from "redux/auth/AuthSlice";
import SharedSlice from "redux/shared/SharedSlice";
import { CompaniesSlice } from "pages/companies";
import { StylesSlice } from "pages/styles";

const oStore = configureStore({
  reducer: {
    AuthSlice,
    SharedSlice,
    CompaniesSlice,
    StylesSlice,
  },
});

export default oStore;
