import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ScreenContainer, Table, Text } from "components";

import { actionGetAllCompanies, selectCompaniesState } from "./redux/CompaniesSlice";
import { CastDate } from "utils/SharedUtils";

const CompaniesList = () => {
  const oDispatch = useDispatch();

  const { aCompanies: aData } = useSelector(selectCompaniesState);

  useEffect(() => {
    oDispatch(actionGetAllCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScreenContainer className="pt-5 pb-10">
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center">
          Cervecerías
        </Text>
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Nombre
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                País
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                Barriles
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                Registro
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                Expiración
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              aData?.length > 0 ? (
                aData.map((oItem, nIndex) => (
                  <Table.Row key={`${oItem.id}_${nIndex}`}>
                    <Table.Col
                      scope="row"
                      className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                    >
                      {oItem.id}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center">
                      {oItem.name}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                      {oItem.country || "-"}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                      {oItem.barrels}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                      {CastDate(oItem.created_at)}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                      {CastDate(oItem.expiration_date)}
                    </Table.Col>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Col className="px-6 py-4 text-center" colSpan={6}>
                    <em>Sin cervecerías registradas</em>
                  </Table.Col>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </section>
    </ScreenContainer>
  );
};

export default CompaniesList;
