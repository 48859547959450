import React from "react";

import Body from "./Body";
import Col from "./Col";
import Footer from "./Footer";
import Header from "./Header";
import Pagination from "./Pagination";
import Row from "./Row";

function Table({ children, ...props }) {
  return (
    <>
      <table
        className="w-full text-sm text-left text-gray-500 dark:text-gray-400 rounded-lg overflow-hidden"
        {...props}
      >
        {children}
      </table>
    </>
  );
}

Table.Body = Body;
Table.Header = Header;
Table.Footer = Footer;
Table.Row = Row;
Table.Col = Col;
Table.Pagination = Pagination;

export default Table;
