import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useLocalStorage from "hooks/useLocalStorage";
import { CONSTANTS_SYSTEM } from "config/constants/System";
import { actionAuthClean, actionRefreshCredentials, selectAuthState } from "redux/auth/AuthSlice";

const { APP_THEME_KEY, APP_TOKEN_KEY } = CONSTANTS_SYSTEM;

export const SharedContext = React.createContext({});

const oDarkThemeUser = window.matchMedia("(prefers-color-scheme: dark)");

export default function SharedContextProvider({ children }) {
  const oDispatch = useDispatch();
  const { oUser, bLogout } = useSelector(selectAuthState);

  const [bIsAuthenticated, setIsAuthenticated] = useState(false);
  const [bDarkTheme, setDarkTheme] = useLocalStorage(APP_THEME_KEY, oDarkThemeUser?.matches || false);
  const [sToken, setToken] = useLocalStorage(APP_TOKEN_KEY, oUser?.token || null);

  useEffect(() => {
    if (oUser?.token) {
      setToken(oUser?.token || sToken);
      setTimeout(() => {
        setIsAuthenticated(true);
      }, 50);
    } else if (sToken) {
      oDispatch(actionRefreshCredentials(sToken));
    } else Logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oUser]);

  useEffect(() => {
    if (bLogout) {
      Logout();
      oDispatch(actionAuthClean());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bLogout]);

  const Logout = () => {
    setToken(null);
    setIsAuthenticated(false);
  };

  return (
    <SharedContext.Provider value={{ bIsAuthenticated, bDarkTheme, setDarkTheme, sToken, Logout }}>
      {children}
    </SharedContext.Provider>
  );
}
