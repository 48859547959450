import React from "react";
import PropsTypes from "prop-types";
import Button from "components/Button";
import Text from "components/Text";
import { BUTTON_CLASS, BUTTON_SIZE } from "config/constants/Buttons";

function ConfirmationModal({
  show: bShow = false,
  onClose = () => { },
  onConfirm = () => { },
  question: sQuestion = null,
  title: sTitle = "Confirmación",
  message: sMessage = "Esta acción no puede revertirse",
}) {

  return (
    bShow && <>
      <div className="fixed top-0 right-0 h-screen w-screen z-40 bg-gray-800 opacity-50" />
      <div className="fixed z-50 top-0 right-0 h-screen w-screen shadow rounded flex items-center justify-center" onClick={onClose} >
        <div className="md:w-96 shadow rounded overflow-hidden max-w-xs md:max-w-md" onClick={oEvent => oEvent.stopPropagation()} >
          <div className="bg-gray-300 dark:bg-gray-900 text-white text-center p-2">
            <Text>{sTitle}</Text>
          </div>
          <div className="bg-white text-dark dark:bg-dark dark:text-gray-300 p-5">
            <Text className="text-center">{sQuestion}</Text>
            {
              sMessage
              && <Text className="text-xs mt-5 text-center md:text-left">
                {sMessage}
              </Text>
            }
            <div className="mt-5 flex justify-center md:justify-end">
              <Button
                onClick={onClose}
                className="mr-4"
                type={BUTTON_CLASS.DANGER}
                size={BUTTON_SIZE.SM}
                label="Cancelar"
              />
              <Button
                onClick={onConfirm}
                type={BUTTON_CLASS.SUCCESS}
                size={BUTTON_SIZE.SM}
                label="Aceptar"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

ConfirmationModal.propTypes = {
  show: PropsTypes.bool.isRequired,
  title: PropsTypes.string,
  question: PropsTypes.string,
  message: PropsTypes.string,
  onConfirm: PropsTypes.func.isRequired,
  onClose: PropsTypes.func.isRequired,
};

export default ConfirmationModal;
