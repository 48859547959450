import React from "react";

const Image = ({
  path: sImage,
  className: sCustomClass = "",
  alt: sAlt = "Image"
}) => {

  if (!sImage)
    return <div className={`bg-white ${sCustomClass}`} />

  return <img className={sCustomClass} src={sImage} alt={sAlt} />;
}

export default Image;
