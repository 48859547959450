import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

function Pagination({ page: nPage, total: nTotalPages, onChange }) {
  let nStartPage = nPage < 5 ? 1 : nPage - 3,
    nCount = nStartPage,
    nFinalPage = nTotalPages > 7 && nPage < 5 ? 7 : nPage + 3 < nTotalPages ? nPage + 3 : nTotalPages,
    aPages = [],
    sItemClass =
      "py-2 px-3 leading-tight text-gray-500 border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white ",
    sArrowClass =
      "py-2 px-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white border";

  while (nCount <= nFinalPage) {
    if (nCount === nPage) aPages.push({ page: nCount, active: true });
    else aPages.push({ page: nCount, active: false });
    nCount++;
  }

  const bStartSection = nStartPage > 1,
    bFinishSection = nFinalPage < nTotalPages;

  return <nav aria-label="Paginación de listado">
    <ul className="inline-flex -space-x-px">
      {bStartSection && (
        <li>
          <span onClick={() => onChange(nPage - 1)} className={`${sArrowClass} rounded-l-lg`}>
            <FiChevronLeft size="16" className="inline-block" />
          </span>
        </li>
      )}
      {aPages.map((oPage, nIndex) => (
        <li key={`page_${oPage.page}`}>
          <span
            onClick={() => onChange(oPage.page)}
            className={
              sItemClass +
              (nIndex === 0 && !bStartSection ? "ml-0 rounded-l-lg " : "border ") +
              (nIndex === aPages.length - 1 && !bFinishSection ? "rounded-r-lg " : "") +
              (oPage.active ? "bg-gray-100 dark:bg-gray-600" : "bg-white dark:bg-gray-800 ")
            }
          >
            {oPage.page}
          </span>
        </li>
      ))}
      {bFinishSection && (
        <li>
          <span onClick={() => onChange(nPage + 1)} className={`${sArrowClass} rounded-r-lg`}>
            <FiChevronRight size="16" className="inline-block" />
          </span>
        </li>
      )}
    </ul>
  </nav>;
}

Pagination.displayName = "Table.Pagination";

export default Pagination;
