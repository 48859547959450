

export const INPUT_TYPE = {
  DEFAULT: "text",
  NUMBER: "number",
  EMAIL: "email",
  PASSWORD: "password",
};

export const INPUT_CLASS = {
  DEFAULT: "float",
  SIMPLE: "simple",
  LABEL_TOP: "label-top",
};
