import { Buffer } from "buffer";
import oAxiosInstance from "config/Axios";

const baseUrl = "auth/";

export const apiCallLogin = (sEmail, sPassword) => {
  return oAxiosInstance.post(baseUrl, { email: sEmail, password: Buffer.from(sPassword || "").toString("base64") });
};

export const apiCallLogout = () => {
  return oAxiosInstance.get(`${baseUrl}logout`);
};

export const apiCallRefreshCredentials = (sToken) => {
  return oAxiosInstance.post(`${baseUrl}check/credentials`, { data: sToken });
};
