import React from "react";

import Text from "./Text";

import { CONSTANTS_MEASURES } from "config/constants/Measures";
import { CONSTANTS_TEXT_STYLE } from "config/constants/Themes";
import { INPUT_CLASS, INPUT_TYPE } from "config/constants/Inputs";

const Input = ({
  value: sValue,
  onBlur = null,
  onFocus = null,
  onKeyUp = null,
  onSubmit = null,
  onChange = null,
  color: sColor = null,
  label: sLabel = null,
  id: sIdentifier = null,
  setRef: onSetRef = null,
  disabled: bDisabled = false,
  className: sClassName = null,
  multiline: bMultiline = false,
  placeholder: sPlaceHolder = "",
  labelClass: sLabelClass = null,
  type: sType = INPUT_TYPE.DEFAULT,
  labelSimple: bLabelSimple = false,
  withoutClass: bWithOutClass = false,
  class: sInputClass = INPUT_CLASS.DEFAULT,
  containerClassName: sContainerClassName = "",
  labelStyle: nLabelStyle = CONSTANTS_TEXT_STYLE.DEFAULT,
}) => {

  if (!sValue)
    sValue = "";

  const aClassName = [
    "text-gray-800 dark:text-white",
    "border-input",
    "rounded-md",
    "px-2",
    "py-2",
    "bg-white dark:bg-gray-800",
    "mt-1",
    CONSTANTS_MEASURES.CLASS.FONT_SIZE_LARGE
  ];

  if (sColor)
    aClassName[0] = sColor;

  if (sClassName)
    aClassName.push(sClassName);

  const renderInput = (oData) => {
    return !bMultiline
      ? <input
        type={sType}
        ref={onSetRef}
        onBlur={onBlur}
        id={sIdentifier}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onSubmit={onSubmit}
        value={sValue || ""}
        disabled={bDisabled}
        placeholder={sPlaceHolder}
        onChange={oEvent => onChange ? onChange(oEvent.target.value, sIdentifier) : null}
        {...oData}
      />
      : <textarea
        ref={onSetRef}
        onBlur={onBlur}
        id={sIdentifier}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onSubmit={onSubmit}
        disabled={bDisabled}
        placeholder={sPlaceHolder}
        onChange={oEvent => onChange ? onChange(oEvent.target.value, sIdentifier) : null}
        value={sValue || ""}
        {...oData}
      >
        {sValue || ""}
      </textarea>
  };

  switch (sInputClass) {
    case INPUT_CLASS.DEFAULT:
    default:
      return <div className={`float-label ${sContainerClassName} transition-all`}>
        {renderInput({ className: aClassName.join(" "), placeholder: sLabel ? null : sPlaceHolder })}
        {
          sLabel ?
            <Text
              className={sValue ? "active" : ""}
              style={nLabelStyle}
              size={CONSTANTS_MEASURES.CLASS.FONT_SIZE_LARGE}
            >
              {sLabel}
            </Text>
            : null
        }
      </div>;
    case INPUT_CLASS.SIMPLE:
      return <div className={sContainerClassName}>
        {
          sLabel ?
            <Text
              style={nLabelStyle}
              simple={bLabelSimple}
              size={CONSTANTS_MEASURES.CLASS.FONT_SIZE_LARGE}
              className={sLabelClass + (sValue ? " active" : "")}
            >
              {sLabel}
            </Text>
            : null
        }
        {renderInput({ className: bWithOutClass ? sClassName : aClassName.join(" ") })}
      </div>;
    case INPUT_CLASS.LABEL_TOP:
      return <div className={sContainerClassName}>
        <Text className="relative top-3 left-1 -mt-3 bg-white dark:bg-gray-800 w-min px-2 text-sm whitespace-nowrap">{sLabel}</Text>
        {renderInput({ className: ("border px-2 py-1 pt-2 rounded-md w-full text-gray-800 dark:text-white bg-white dark:bg-gray-800 " + sClassName) })}
      </div>;

  }
}

export default Input;
