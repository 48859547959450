import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { CONSTANTS_COLORS } from "config/constants/Colors";

const Loading = ({ open: bIsOpen = false }) => {
  if (!bIsOpen) return null;

  return (
    <div className="w-screen h-screen top-0 left-0 flex fixed z-50 bg-gray-900 bg-opacity-60 items-center">
      <AiOutlineLoading3Quarters
        size={35}
        className="m-auto animate-spin"
        color={CONSTANTS_COLORS.COLORS.PRIMARY_COLOR}
      />
    </div>
  );
};

export default Loading;
