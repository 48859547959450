import React, { useEffect, useMemo, useState } from "react";
import { FiPlus, FiSearch, FiTrash } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { FaPen } from "react-icons/fa";

import { Button, Input, Modal, ScreenContainer, Table, Text, ConfirmationModal } from "components";

import { actionGetAllStyles, actionStylesClean, actionStylesDelete, actionStylesSave, actionStylesUpdate, selectStylesState } from "./redux/StylesSlice";
import { actionMessage } from "redux/shared/SharedSlice";

import { BUTTON_CLASS, BUTTON_SIZE } from "config/constants/Buttons";
import { CONSTANTS_MEASURES } from "config/constants/Measures";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import { INPUT_CLASS } from "config/constants/Inputs";

const CompaniesList = () => {
  const oDispatch = useDispatch();

  const { aStyles, bSuccess } = useSelector(selectStylesState);

  const [filter, setFilter] = useState("");
  const [name, setName] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isConfirmation, showConfirmation] = useState(false);
  const [itemDelete, setItemDelete] = useState(null);
  const [itemUpdateId, setItemUpdateId] = useState(null);
  const [itemUpdateName, setItemUpdateName] = useState(null);

  useEffect(() => {
    oDispatch(actionGetAllStyles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bSuccess) {
      setName("");
      setIsAdd(false);
      showConfirmation(false);
      setItemDelete(null);
      setItemUpdateId(null);
      setItemUpdateName(null);
      oDispatch(actionStylesClean());
      oDispatch(actionGetAllStyles());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bSuccess]);

  const aData = useMemo(() => {
    return !filter
      ? aStyles
      : aStyles?.filter(oItem => (
        oItem.name?.toUpperCase()?.includes(filter.toUpperCase()) ||
        oItem.id?.toString()?.toUpperCase()?.includes(filter.toUpperCase())
      ));
  }, [aStyles, filter]);

  const onSave = () => {
    if (!name.trim())
      return oDispatch(actionMessage({ message: "Nombre requerido" }));
    oDispatch(actionStylesSave({ name }));
  };

  const onDelete = (isConfirm = false) => {
    if (!isConfirm)
      return showConfirmation(true);
    oDispatch(actionStylesDelete(itemDelete?.id));
  };

  const onUpdate = () => {
    if (!itemUpdateName.trim())
      return oDispatch(actionMessage({ message: "Nombre requerido" }));
    oDispatch(actionStylesUpdate({ id: itemUpdateId, name: itemUpdateName }));
  };

  return (
    <ScreenContainer className="pt-5 pb-10">
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center">
          Estilos de cerveza
        </Text>
        <Button
          label="Nuevo"
          onClick={() => setIsAdd(true)}
          type={BUTTON_CLASS.SUCCESS}
          containerClassName="ml-auto"
          className="flex align-middle"
          size={BUTTON_SIZE.RESPONSIVE}
          icon={<FiPlus size={30} className="pl-2" color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />} />
      </section>
      <section className="rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 md:mt-10">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={filter}
            placeholder="Buscar"
            onChange={setFilter}
            class={INPUT_CLASS.SIMPLE}
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Nombre
              </Table.Col>
              <Table.Col />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              aData?.length > 0 ? (
                aData.map((oItem, nIndex) => (
                  <Table.Row key={`${oItem.id}_${nIndex}`}>
                    <Table.Col
                      scope="row"
                      className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                    >
                      {nIndex + 1}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center">
                      {oItem.name}
                    </Table.Col>
                    <Table.Col>
                      <div className="flex justify-center">
                        <Button
                          className="mr-2"
                          size={BUTTON_SIZE.XS}
                          type={BUTTON_CLASS.WARNING}
                          onClick={() => { setItemUpdateId(oItem.id); setItemUpdateName(oItem.name); }}
                          icon={<FaPen size={CONSTANTS_MEASURES.FONT_SIZE_MEDIUM} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                        />
                        <Button
                          size={BUTTON_SIZE.XS}
                          type={BUTTON_CLASS.DANGER}
                          onClick={() => { setItemDelete(oItem); onDelete(); }}
                          icon={<FiTrash size={CONSTANTS_MEASURES.FONT_SIZE_MEDIUM} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                        />
                      </div>
                    </Table.Col>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Col className="px-6 py-4 text-center" colSpan={2}>
                    <em>Sin estilos</em>
                  </Table.Col>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </section>
      {
        isAdd &&
        <Modal show={isAdd} onClose={() => setIsAdd(false)} title="Agregar nuevo estilo">
          <div className="md:w-96 bg-white text-dark dark:bg-dark dark:text-gray-300 py-3 px-5">
            <Input
              value={name}
              onChange={setName}
              className="w-full"
              placeholder="Nombre"
              containerClassName="mt-4"
              class={INPUT_CLASS.SIMPLE}
              label="Nombre del nuevo estilo"
            />
          </div>
          <div className="mt-5 flex justify-center md:justify-end">
            <Button
              onClick={() => setIsAdd(false)}
              className="mr-4"
              type={BUTTON_CLASS.DANGER}
              size={BUTTON_SIZE.SM}
              label="Cancelar"
            />
            <Button
              onClick={onSave}
              type={BUTTON_CLASS.SUCCESS}
              size={BUTTON_SIZE.SM}
              label="Guardar"
            />
          </div>
        </Modal>
      }
      {
        itemUpdateId &&
        <Modal show={!!itemUpdateId} onClose={() => setItemUpdateId(null)} title="Actualizar estilo">
          <div className="md:w-96 bg-white text-dark dark:bg-dark dark:text-gray-300 py-3 px-5">
            <Input
              className="w-full"
              placeholder="Nombre"
              value={itemUpdateName}
              label="Nombre del estilo"
              containerClassName="mt-4"
              class={INPUT_CLASS.SIMPLE}
              onChange={setItemUpdateName}
            />
          </div>
          <div className="mt-5 flex justify-center md:justify-end">
            <Button
              label="Cancelar"
              className="mr-4"
              size={BUTTON_SIZE.SM}
              type={BUTTON_CLASS.DANGER}
              onClick={() => setItemUpdateId(null)}
            />
            <Button
              label="Guardar"
              onClick={onUpdate}
              size={BUTTON_SIZE.SM}
              type={BUTTON_CLASS.SUCCESS}
            />
          </div>
        </Modal>
      }
      <ConfirmationModal
        show={isConfirmation}
        onConfirm={() => onDelete(true)}
        onClose={() => showConfirmation(false)}
        question={`¿Desea eliminar el estilo ${itemDelete?.name || ""}?`}
      />
    </ScreenContainer>
  );
};

export default CompaniesList;
