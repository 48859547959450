import React from "react";

function Header({ children }) {
  return (
    <>
      <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
        {children}
      </thead>
    </>
  );
}

Header.displayName = "Table.Header";

export default Header;
