
export const BUTTON_CLASS = {
  PRIMARY: "primary",
  WARNING: "warning",
  SUCCESS: "success",
  CANCEL_OUTLINE: "cancel_outline",
  OUTLINE: "outline",
  DANGER: "danger",
};

export const BUTTON_SIZE = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
  RESPONSIVE: "responsive",
};

export const BUTTON_ICON_POSITION = {
  LEFT: "left",
  RIGHT: "right",
};